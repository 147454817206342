.card {
    box-shadow: 0 1px 1px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 2px 1px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 3px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .2);

    @media print {
        box-shadow: none !important;
        display: block !important;
    }

    &:not([class*=border]) {
        border: 0;
    }

    .card-footer {
        &:empty {
            border-top: 0;
            padding: 0;
        }

        @media print {
            display: none !important;
        }
    }
}

.card-header {
    min-height: 45px;

    .btn-sm {
        display: flex;
        margin-top: 1px;
    }

    &.card-header-primary {
        background-color: var(--cui-primary);
        color: var(--cui-white);

        .btn {
            color: var(--cui-white);
        }
    }
}

.card-title-col {
    min-height: 28px;
    display: flex;
    align-items: center;
}

.card-title-col-with-btn {
    max-width: calc(100% - 58px) !important;
}
