.rotate-45 {
    transform: rotate(45deg);
}

.btn-icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 35px;
    justify-content: center;
    padding: 0;
    width: 35px;

    &.offset-icon-1 {
        margin-left: 45px;
    }

    .icon {
        height: 1.25rem;
        font-size: 1.25rem;
        width: 1.25rem;
    }
}
