.popover {
    box-shadow: 0 0 1px 0px rgba(255, 255, 255, .5);
}

.popover-header {
    background-color: var(--cui-primary) !important;
    color: var(--cui-white) !important;
}

.popover-body {
    padding: .5rem !important;

    .table {
        margin-bottom: 0;

        td {
            height: auto;
        }
    }
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^="bottom"] {
    .popover-header {
        &::before {
            border-bottom: 1px solid var(--cui-primary) !important;
        }
    }

    .popover-arrow {
        &::after {
            border-bottom-color: var(--cui-primary) !important;
        }
    }
}
