.ma {
    margin: auto !important;
}

.mw-600 {
    max-width: 600px !important;
}

.px-4-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.px-lg-4-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.pt-2-5 {
    padding-top: .75rem !important;
}

@include media-breakpoint-up(md) {
    .text-lg-nowrap {
        white-space: nowrap !important;
    }
}

@include media-breakpoint-only(xs) {
    .w-xs-10 {
        width: 10% !important;
    }
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-33 {
    width: 33% !important;
}

.w-40 {
    width: 40% !important;
}

.w-100-row {
    width: calc(100% + var(--cui-gutter-x)) !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}
