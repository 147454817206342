.table {
    @media print {
        display: table !important;
        max-width: 100% !important;
        page-break-after: always !important;

        *,
        .text-nowrap {
            white-space: normal !important;
        }
    }

    tr {
        @media print {
            position: static !important;
            break-inside: avoid !important;
        }
    }

    th {
        @media print {
            position: static !important;
            break-inside: avoid !important;
        }

        .btn-text {
            color: inherit;
            font-weight: inherit;
        }
    }

    td {
        height: 3.25rem;

        @media print {
            position: static !important;
            break-inside: avoid !important;
        }
    }

    &.table-sm {
        td {
            height: auto;
        }
    }

    .table-dark {
        th {
            color: $gray-700;
        }
    }

    &.table-outline {
        border: 1px solid $gray-200;
    }
}

.table-ms-0 {
    margin-left: -$table-cell-padding-x;
}


.table-me-0 {
    margin-right: -$table-cell-padding-x;
}

.cell-w-1-icons {
    width: 58px !important;
}

.cell-w-2-icons {
    width: 101px !important;
}

.table-mobile {
    .was-validated :invalid,
    .is-invalid {
        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .table-mobile {
        display: block;

        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
            height: auto;
        }

        thead {
            tr {
                left: -9999px;
                position: absolute;
                top: -9999px;
            }
        }

        tr {
            &.desktop-error {
                display: none;
            }

            + tr {
                border-top-width: 1px;
            }
        }

        td {
            border: none;
            position: relative;
            padding-left: 45% !important;
            white-space: normal;
            text-align: left;
            width: 100% !important;

            &::before {
                content: attr(data-title);
                font-weight: $font-weight-semibold;
                left: 6px;
                padding-right: 10px;
                position: absolute;
                text-align: left;
                top: 15px;
                white-space: nowrap;
                width: 40%;
            }
        }

        .was-validated :invalid,
        .is-invalid {
            ~ .invalid-feedback,
            ~ .invalid-tooltip {
                display: block;
            }
        }
    }

    .table-mobile-col-min-100px {
        th,
        td {
            min-width: 100px;
        }
    }

    .table-mobile-short-title {
        td,
        th {
            &[data-title] {
                position: relative;
                font-size: 0;

                &::before {
                    content: attr(data-title);
                    display: block;
                    font-size: var(--cui-body-font-size);
                }
            }
        }
    }

}
