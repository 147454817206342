.header {
    background: shift-color($gray-base, +26) !important;
    border-bottom: 0 !important;
    min-height: 100px;

    @media print {
        box-shadow: none !important;
        position: static !important;

        .navbar-toggler,
        .navbar-expand-lg .navbar-collapse {
            display: none !important;
        }
    }
}

.navbar-nav {
    .header & {
        flex-basis: calc(100% + var(--cui-gutter-x));
    }
}

.navbar-dark {
    --cui-navbar-brand-color: white !important;
    --cui-navbar-brand-hover-color: white !important;
    --cui-navbar-color: white !important;
    --cui-navbar-hover-color: white !important;
    --cui-navbar-active-color: white !important;
}
