.form-control-reset {
    bottom: 7px;
    cursor: pointer;

    .icon {
        transform-origin: center;
        transition: $transition-base;
        will-change: auto;
    }

    &:hover,
    &:focus {
        .icon {
            transform: scale(1.2);
        }
    }
}

.form-control {
    @include media-breakpoint-only(xs) {
        font-size: 1rem !important;
    }

    &.is-invalid {
        + .form-control-reset {
            margin-right: 2rem !important;
        }
    }
}

.form-label {
    font-weight: $font-weight-bold;
}

.form-check-inline {
    padding-top: add($input-padding-y, $input-border-width);
}

.form-checkbox {
    .form-check-input {
        width: 1.5em !important;
        height: 1.5em !important;
        margin-top: .5em !important;
    }
}

.form-select {
    @include media-breakpoint-only(xs) {
        font-size: 1rem !important;
    }

    background-color: var(--cui-white) !important;
}

// CoreUI pro
.form-multi-select {
    .form-multi-select-search {
        background-color: transparent;
    }
}

// package react-datepicker
.react-datepicker-wrapper {
    max-width: 285px;
}

.react-datepicker__portal {
    background-color: var(--cui-modal-backdrop-bg, rgba(0, 0, 0, .21));

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2rem !important;
        line-height: 2rem !important;
    }
}

.react-datepicker__close-icon {
    transition: $transition-fade;

    &::after {
        background-color: var(--cui-input-bg, #f7f7f7) !important;
        color: var(--cui-form-label-color) !important;
        font-size: 16px !important;
    }

    &:hover,
    &:focus {
        &::after {
            color: var(--cui-primary);
        }
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: btn-color($primary, $btn-hover-bg-shade-amount, $btn-hover-bg-tint-amount);
    opacity: .9;

    &:hover,
    &:focus {
        background-color: btn-color($primary, $btn-hover-bg-shade-amount, $btn-hover-bg-tint-amount);
        opacity: .8;
    }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: $primary;

    &:hover,
    &:focus {
        background-color: btn-color($primary, $btn-hover-bg-shade-amount, $btn-hover-bg-tint-amount);
    }
}
