* {
    hyphens: auto;
}

a {
    &:not([href]) {
        text-decoration: none;
    }
}

.text-truncate {
    display: block;
    max-width: calc(100vw / 3 - 270px);
    min-width: 270px;
}

.text-truncate-small {
    max-width: calc(100vw / 3 - 310px);
}

.text-valign-middle {
    vertical-align: middle;
}

.text-white {
    p {
        a {
            color: var(--cui-white);
        }
    }
}
