.pagination {
    @media print {
        display: none !important;
    }
}

.page-link {
    cursor: pointer;
}

.page-item {
    &.active {
        .page-link {
            font-weight: bolder;
        }
    }
}
