@media print {
    .wrapper.d-flex,
    .row {
        display: block !important;
    }

    .btn {
        display: none !important;
    }
}
