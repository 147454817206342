.btn {
    &.btn-disabled {
        cursor: auto;
        opacity: .5;
    }

    &.active {
        &:hover,
        &:focus {
            background-color: var(--cui-btn-hover-bg);
        }
    }
}

.btn-contrast {
    $variant: btn-color-map($contrast, $contrast);

    $background: map-get($variant, 'bg');
    $border-color: map-get($variant, 'border-color');
    $color: map-get($variant, 'color');
    $hover-background: map-get($variant, 'hover-bg');
    $hover-border-color: map-get($variant, 'hover-border-color');
    $hover-color: map-get($variant, 'hover-color');
    $active-background: map-get($variant, 'active-bg');
    $active-border-color: map-get($variant, 'active-border-color');
    $active-color: map-get($variant, 'active-color');
    $disabled-background: map-get($variant, 'disabled-bg');
    $disabled-border-color: map-get($variant, 'disabled-border-color');
    $disabled-color: map-get($variant, 'disabled-color');
    $shadow: map-get($variant, 'shadow');

    --cui-btn-bg: #{$background};
    --cui-btn-border-color: #{$border-color};
    --cui-btn-color: #{$white}; // override, due to design color
    --cui-btn-hover-bg: #{$hover-background};
    --cui-btn-hover-border-color: #{$hover-border-color};
    --cui-btn-hover-color: #{$white}; // override, due to design color
    --cui-btn-active-bg: #{$active-background};
    --cui-btn-active-border-color: #{$active-border-color};
    --cui-btn-active-color: #{$active-color};
    --cui-btn-disabled-bg: #{$disabled-background};
    --cui-btn-disabled-border-color: #{$disabled-border-color};
    --cui-btn-disabled-color: #{$disabled-color};
    --cui-btn-shadow: #{$shadow};
}

.btn-text {
    box-shadow: none !important;
    cursor: default !important;
    padding: 0 !important;
    text-decoration: none !important;

    &.btn-sm {
        font-size: var(--cui-body-font-size);
    }
}
