.modal-content {
    min-height: 462px;

    .react-confirm-alert & {
        min-height: 262px;

        * {
            text-align: left;
            white-space: normal;
        }
    }
}

.modal {
    .modal-dialog {
        .modal-body {
            font-size: var(--cui-body-font-size);
            font-weight: var(--cui-body-font-weight);
            line-height: var(--cui-body-line-height);
        }

        @include media-breakpoint-only(xs) {
            .modal-footer {
                justify-content: flex-start;

                > * {
                    margin: 0;
                }

                .row {
                    --cui-gutter-x: 1.5rem;

                    margin-right: calc(-.5 * var(--cui-gutter-x));
                    margin-left: calc(-.5 * var(--cui-gutter-x));
                }
            }
        }
    }
}

.modal-dialog-scrollable {
    .modal-body {
        overscroll-behavior-y: contain;
        scrollbar-gutter: stable;
    }
}
